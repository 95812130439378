import * as React from "react"
import {bodyText, mobileBodyText, getContainerStyle, urlColor, mobileURLColor} from "../components/styles";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import {wrapContent} from "../components/content";
import { StaticImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";

// styles
const paragraphStyles = {
    ...bodyText,
}

const mobileParagraphStyles = {
    ...mobileBodyText,
}

const containerStyle = {
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    gap: "36px"
}



const MobileProfPic = () => {
    const imageSize = 200
    return (
        <StaticImage src={"../images/profilepic.JPG"} placeholder={"none"}
                     alt={"Nicholas Wu"} layout={"fixed"}
                     width={imageSize} height={imageSize} imgStyle={{borderRadius:imageSize/2}}/>
    )
}

const DesktopProfPic = () => {
    const imageSize = 300
    return (
        <StaticImage src={"../images/profilepic.JPG"} placeholder={"none"}
                     alt={"Nicholas Wu"} layout={"fixed"}
                     width={imageSize} height={imageSize} imgStyle={{borderRadius:imageSize/2}}/>
    )
}

const IndexPage = () => {
    const breakpoints = useBreakpoint()
    return (
        <div style={{...getContainerStyle(breakpoints), ...containerStyle}}>
            { breakpoints.sm ? <MobileProfPic /> : <DesktopProfPic />}
            <p style={breakpoints.sm ? mobileParagraphStyles : paragraphStyles}>
            I am a PhD candidate in economics at Yale University. My research interests are in microeconomic theory. I work on mechanism design and dynamic games, with a focus on information design, bandit problems, and learning.
            </p>
            <p>
                <OutboundLink style={breakpoints.sm ? mobileURLColor : urlColor} href={"https://drive.google.com/file/d/1OGIkX-aXdz75U-cqxeDBWviOWPLJc-0Y/view?usp=sharing"}>
                    My CV can be found here
                </OutboundLink>.
            </p>
            <p style={{fontSize: "0.7em"}}>
                This website was last updated on December 3, 2024.
            </p>

        </div>
    )
}

export default wrapContent(IndexPage)
